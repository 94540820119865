'use strict';

angular.module('kljDigitalLibraryApp')
    .config(["$stateProvider", function($stateProvider) {
        $stateProvider
            .state('index', {
                url: '/index',
                templateUrl: 'app/index/index.html',
                controller: 'IndexCtrl'
            })
            .state('expiry', {
                url: '/expiry',
                templateUrl: 'app/tpl/expiry.html',
                controller: 'IndexCtrl'
            });

    }]);